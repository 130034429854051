<template>
  <div id="container">

    <v-card max-width="600px" style="margin-right: auto; margin-left: auto; margin-top: 20px;">
      <v-card-text>
        <span class="mx-auto text-h5">Company information</span>
        <table cellspacing="15px" class="mx-auto" style="line-height:40px;">
          <tr>
            <td>
              <v-icon left>mdi-cellphone-cog</v-icon>
              <span class="subtitle-1">Mobile:</span>
            </td>
            <td>
              <v-text-field v-model="company.mobile"/>
            </td>
          </tr>
          <tr>
            <td>
              <v-icon left>mdi-map-marker-outline</v-icon>
              <span class="subtitle-1">Address: </span>
            </td>
            <td>
              <v-text-field v-model="company.address"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-btn small color="grey darken-2" outlined class="px-8" @click="saveInfo">
                <v-icon left>
                  mdi-check-underline
                </v-icon>
                Save</v-btn>
            </td>
          </tr>
        </table>
        <span class="text--darken-2">* This is has no effect, just a configuration example!</span>
      </v-card-text>
    </v-card>
    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </div>
</template>

<script>
import CompanyService from "@/service/CompanyService";

export default {
  name: "Settings",
  data() {
    return {
      errorMessage: '',
      showOverlay: true,
      company: {
        address: "",
        mobile: "",
        purchaseCommission: 0,
        saleCommission: 0
      }
    }
  },

  async created() {
    let company;
    try {
      company = await CompanyService.getInfo();
    } catch (e) {
      this.errorMessage = e.message;
      return;
    } finally {
      this.showOverlay = false;
    }
    this.company = company;
  },

  methods: {
  }

}
</script>

<style scoped>
#container {
  margin-bottom: 60px;
}
</style>