import axios from 'axios';
import {BackendError} from "@/exceptions/BackendError";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL + 'company';

class CompanyService {

    currentToken() {
        return localStorage.getItem('token');
    }

    getInfo() {
        return axios.get(API_BASE_URL + '/info',
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken()
                }
            }).then(response => response.data)
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.data)
                        throw new BackendError(ex.response.data.errorMessage);
                }
                throw new BackendError("Problem gjatë leximit të informacionit!");
            });
    }

    refreshCompanyData() {
        axios.get(API_BASE_URL + '/info',
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken()
                }
            }).then(response => {
            this.setCurrentLocalInfo(response.data);
        })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.data)
                        throw new BackendError(ex.response.data.errorMessage);
                }
                throw new BackendError("Problem gjatë leximit të informacionit!");
            });
    }

    getLocalCompanyInfo() {
        const currentCompanyInfo = localStorage.getItem('current-company-info');
        if (!currentCompanyInfo)
            return null;
        return JSON.parse(currentCompanyInfo);
    }

    setCurrentLocalInfo(companyInfo) {
        localStorage.setItem('current-company-info', JSON.stringify(companyInfo));
    }

    updateInfo(address, mobile) {
        return axios.put(API_BASE_URL + '/info', {address: address, mobile: mobile},
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken()
                }
            }).then(response => response.data)
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.data)
                        throw new BackendError(ex.response.data.errorMessage);
                }
                throw new BackendError("Problem gjatë përditësimit të informacionit!");
            });
    }

    updateCommission(sale, purchase) {
        return axios.put(API_BASE_URL + '/commission/', {sale: sale, purchase: purchase},
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken()
                }
            }).then(response => response.data) //todo: update local storage
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.data)
                        throw new BackendError(ex.response.data.errorMessage);
                }
                throw new BackendError("Problem gjatë përditësimit të komisionit!");
            });
    }

}

export default new CompanyService();